
























import {Component, Vue} from 'vue-property-decorator';
import {saveAs} from 'file-saver';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import ExportRepository from '@/api/repositories/ExportRepository';
import moment from 'moment';

const exportRepository: ExportRepository = RepositoryFactory.get('export');

@Component({})
export default class ExportAccountingComponent extends Vue {

  /**
   * The date the accounting is for
   * @private
   */
  private accountingDate: string = '';

  /**
   * The max value for the datePicker is the last month, because the current month is not over and the accounting could not be created.
   * Same for future accountings
   * @private
   */
  private lastMonth: string = '';

  public mounted() {
    const today = new Date();
    this.lastMonth = new Date(today.setMonth(today.getMonth() - 1)).toISOString();
    this.accountingDate = moment(this.lastMonth).format(this.$t('EXPORT.DATE_FORMAT').toString());
  }

  /**
   * Download the Accounting file from the backend
   */
  public async onExport() {
    try {
      const exampleFileBlob: any = await exportRepository.getAccountingFile(this.accountingDate);
      saveAs(exampleFileBlob, `${this.$t('EXPORT.FILE_NAME')}_${this.accountingDate}.csv`);
      this.$emit('exitModal');
    } catch (e) {
      // if the path of the status does not exists, use a default one
      let path = `EXPORT.${e.status}`;
      if ((typeof this.$t(`EXPORT.${e.status}`)) === 'string') {
        path = 'EXPORT.DEFAULT';
      }
      this.$notifyErrorSimplified(path);
    }
  }
}
